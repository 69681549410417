export const hcpAddressToMapUri = node => {
  let address = node.address_line_1 ? `${node.address_line_1} ` : ''
  address += node.address_line_2 ? `${node.address_line_2} ` : ''
  address += node.city ? `${node.city} ` : ''
  address += node.state ? `${node.state} ` : ''
  address += node.zip ? `${node.zip}` : ''
  if (!address) {
    return null
  }
  const url = encodeURI(
    `https://www.google.com/maps/search/?api=1&query=${address}`
  )
  return url
}

export const withHttp = url =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url
