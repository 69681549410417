import React from 'react'
import GoogleMapReact from 'google-map-react'
import { Icon } from '@iconify/react'
import mapMarker from '@iconify/icons-el/map-marker'

import * as Styled from './styles/HcpdGoogleMap.styles'

const LocationPin = () => (
  <Styled.Pin>
    <Icon icon={mapMarker} style={{ color: 'red', fontSize: '2rem' }} />
  </Styled.Pin>
)

const HcpdGoogleMap = ({ location }) => (
  <Styled.MapContainer>
    <Styled.Map>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.GATSBY_GOOGLE_MAPS_KEY
            ? process.env.GATSBY_GOOGLE_MAPS_KEY
            : null,
        }}
        defaultCenter={location}
        defaultZoom={14}
      >
        <LocationPin lat={location.lat} lng={location.lng} />
      </GoogleMapReact>
    </Styled.Map>
  </Styled.MapContainer>
)

export default HcpdGoogleMap
