import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'

export const MapContainer = styled.div`
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    width: 180px;
  }

  @media screen and (min-width: 1024px) {
    width: 300px;
  }

  @media screen and (min-width: 1300px) {
    width: 380px;
  }

  @media ${DEVICE.tabletL} {
    justify-content: flex-end;
  }
`

export const Map = styled.div`
  width: 300px;

  @media screen and (min-width: 768px) {
    width: 180px;
  }

  @media screen and (min-width: 1024px) {
    width: 300px;
  }

  @media screen and (min-width: 1300px) {
    width: 380px;
  }
`

export const Pin = styled.div`
  color: var(--highlight-blue);
`
