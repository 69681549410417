import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { BottomLineGoogleAd } from '../styles/GoogleAdsCommon.styles'

const HCP_BOTTOM_LINE_GOOGLE_AD_QUERY = graphql`
  {
    prismicGeneralSiteSettings {
      data {
        hcp_detail_page_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
      }
    }
  }
`

const HcpdDetailPageBottomLineGoogleAd = () => (
  <StaticQuery
    query={`${HCP_BOTTOM_LINE_GOOGLE_AD_QUERY}`}
    render={data => {
      const adBlockId =
        data?.prismicGeneralSiteSettings.data?.hcp_detail_page_ad?.document.id
      if (!adBlockId) {
        return null
      }
      return <BottomLineGoogleAd id={adBlockId} />
    }}
  />
)

export default HcpdDetailPageBottomLineGoogleAd
