import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import Layout from '../components/Layout'
import HcpdDetailPage from '../components/HealthCareProviderDirectory/HcpdDetailPage'
import HcpdDetailPageBottomLineGoogleAd from '../components/HealthCareProviderDirectory/HcpdDetailPageBottomLineGoogleAd'

export const query = graphql`
  query HealthCareProviderPageQuery($id: String) {
    healthCareProvider(id: { eq: $id }) {
      id
      slug
      address_line_1
      address_line_2
      city
      clinic_email
      clinic_name
      clinical_trials
      country
      credentials
      designation
      email
      first_name
      how_many_patients_with_ra
      how_many_patients_with_psoriasis
      is_accepting_new_patients
      languages_spoken
      last_name
      manager
      manager_email
      middle_name
      is_in_mpm
      is_in_presidents_council
      is_in_corrona_registry
      payment_methods
      phone
      practice_type
      sex
      specialties
      state
      treatments
      url
      zip
      latitude
      longitude
      philosophy
      telehealth
    }
  }
`

const HealthCareProviderPage = ({ data }) => {
  const node = data.healthCareProvider
  if (!node) {
    return null
  }

  // TODO: SEO on these pages?
  return (
    <Layout>
      <HcpdDetailPage node={node} />
      <HcpdDetailPageBottomLineGoogleAd />
    </Layout>
  )
}

HealthCareProviderPage.propTypes = {
  data: object,
}

export default HealthCareProviderPage
