import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { PrismicRichText } from '@prismicio/react'
import { StaticQuery, graphql } from 'gatsby'

import * as Styled from './styles/HcpdDetailPage.styles'
import { RegionContainer } from '../styles/Utility.styles'
import {
  PresidentsCouncilBadge,
  ProfessionalMembershipBadge,
  HcpdBackArrow,
  TelehealthIcon,
} from '../Icons'
import { hcpAddressToMapUri, withHttp } from './hcpdUtil'
import HcpdGoogleMap from './HcpdGoogleMap'

import COREVITAS_BADGE from '../../images/corevitas.png'

const BackButton = () => (
  <Styled.BackButtonContainer to="/health-care-provider-directory/">
    <HcpdBackArrow />
    <span>Back To Results</span>
  </Styled.BackButtonContainer>
)

const CategoryColumn = ({ title, items }) => (
  <>
    {items && items[0] !== null && (
      <Styled.CategoryColumn>
        <Styled.CategoryHeading>{title}</Styled.CategoryHeading>
        <Styled.CategoryList>
          {items.map(item => (
            <li key={item}>{item}</li>
          ))}
        </Styled.CategoryList>
      </Styled.CategoryColumn>
    )}
  </>
)

const CredentialsColumn = ({
  credentials,
  corrona,
  presidentsCouncil,
  professionalMembership,
}) => (
  <Styled.CategoryColumn>
    <Styled.CategoryHeading>Credentials</Styled.CategoryHeading>
    <Styled.CategoryList>
      {credentials && <li>{credentials}</li>}
      {presidentsCouncil && (
        <li>
          <PresidentsCouncilBadge />
        </li>
      )}
      {professionalMembership && (
        <li>
          <ProfessionalMembershipBadge />
        </li>
      )}
      {corrona && (
        <li>
          <img alt="CorEvitas Registry" src={COREVITAS_BADGE} />
        </li>
      )}
    </Styled.CategoryList>
  </Styled.CategoryColumn>
)

const SecondaryClinicDetails = ({ node }) => (
  <Styled.ClinicDetailsGroup>
    {node.specialties.length > 0 && (
      <CategoryColumn title="Specialties" items={node.specialties} />
    )}
    {node.treatments.length > 0 && (
      <CategoryColumn title="Treatments" items={node.treatments} />
    )}
    {node.services && <CategoryColumn title="Services" items={node.services} />}
    {node.clinical_trials.length > 0 && (
      <CategoryColumn
        title="Clinical Trial Participation"
        items={node.clinical_trials}
      />
    )}
    {node.sex && <CategoryColumn title="Gender" items={[node.sex]} />}
    {node.languages_spoken.length > 0 && (
      <CategoryColumn title="Languages" items={node.languages_spoken} />
    )}
    {node.payment_methods.length > 0 && (
      <CategoryColumn title="Payment" items={node.payment_methods} />
    )}
    {(node.credentials ||
      node.is_in_corrona_registry ||
      node.is_in_presidents_council ||
      node.is_in_mpm) && (
      <CredentialsColumn
        credentials={node.credentials}
        corrona={node.is_in_corrona_registry}
        presidentsCouncil={node.is_in_presidents_council}
        professionalMembership={node.is_in_mpm}
      />
    )}
  </Styled.ClinicDetailsGroup>
)

const PracticeInfoColumn = ({ node }) => (
  <Styled.PracticeInfoColumn>
    <Styled.CategoryHeading>Practice</Styled.CategoryHeading>
    <Styled.PracticeName>{node.clinic_name}</Styled.PracticeName>
    <Styled.PracticeContactDetailBlock>
      <a
        href={hcpAddressToMapUri(node)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <p>
          {node.address_line_1}
          {node.address_line_2 !== '' && `, ${node.address_line_2}`}
        </p>
        <p>
          {node.city}, {node.state} {node.zip}
        </p>
        <p>{node.country === 'US' ? 'United States' : node.country}</p>
      </a>
    </Styled.PracticeContactDetailBlock>
    <Styled.PracticeContactDetailBlock>
      {node.phone && (
        <a href={`tel:+1${node.phone}`}>
          <p>{node.phone}</p>
        </a>
      )}
      {node.url && (
        <a href={withHttp(node.url)} rel="noopener noreferrer" target="_blank">
          <p>Visit Website</p>
        </a>
      )}
      {node.telehealth && (
        <Styled.TelehealthRow>
          <TelehealthIcon />
          <span>Telehealth Provider</span>
        </Styled.TelehealthRow>
      )}
    </Styled.PracticeContactDetailBlock>
  </Styled.PracticeInfoColumn>
)

const TypeAndAcceptingNewPatientsColumn = ({ node }) => (
  <Styled.TypeAndAcceptingColumn>
    {node.practice_type.length > 0 && (
      <React.Fragment key={uuidv4()}>
        <Styled.CategoryHeading>Type of Practice</Styled.CategoryHeading>
        <Styled.CategoryList>
          {node.practice_type.map(type => (
            <li key={type}>{type}</li>
          ))}
        </Styled.CategoryList>
      </React.Fragment>
    )}
    {node.is_accepting_new_patients !== null && (
      <React.Fragment key={uuidv4()}>
        <Styled.CategoryHeading style={{ marginTop: '10px' }}>
          Accepting New Patients?
        </Styled.CategoryHeading>
        <Styled.CategoryList>
          <li>{node.is_accepting_new_patients === true ? 'Yes' : 'No'}</li>
        </Styled.CategoryList>
      </React.Fragment>
    )}
  </Styled.TypeAndAcceptingColumn>
)

const PrimaryClinicDetails = ({ node }) => (
  <Styled.PrimaryClinicDetailsGroup>
    <PracticeInfoColumn node={node} />
    <TypeAndAcceptingNewPatientsColumn node={node} />
    <HcpdGoogleMap location={{ lat: node.latitude, lng: node.longitude }} />
  </Styled.PrimaryClinicDetailsGroup>
)

const DISCLAIMER_QUERY = graphql`
  {
    prismicGeneralSiteSettings {
      data {
        health_care_provider_disclaimer_text {
          richText
        }
      }
    }
  }
`

const Disclaimer = () => (
  <StaticQuery
    query={`${DISCLAIMER_QUERY}`}
    render={data => {
      const disclaimer =
        data?.prismicGeneralSiteSettings?.data
          ?.health_care_provider_disclaimer_text ?? ''
      return (
        <Styled.Disclaimer>
          {disclaimer && <PrismicRichText field={disclaimer.richText} />}
        </Styled.Disclaimer>
      )
    }}
  />
)

const HcpdDetailPage = ({ node }) => (
  <RegionContainer>
    <BackButton />
    <Styled.HeroTitle>
      {node.first_name}
      {node.middle_name ? ` ${node.middle_name}` : ''} {node.last_name}
      {node.designation && `, ${node.designation}`}
    </Styled.HeroTitle>
    {node.philosophy && <Styled.HeroBody>{node.philosophy}</Styled.HeroBody>}
    <Styled.ClinicDetailsSection>
      <PrimaryClinicDetails node={node} />
      <Styled.Divider />
      <SecondaryClinicDetails node={node} />
      <Styled.Divider />
      <Disclaimer />
    </Styled.ClinicDetailsSection>
  </RegionContainer>
)

export default HcpdDetailPage
